import axios from "axios";

let API_URL = "";

if (process.env.REACT_APP_ENV === "developer") {

    API_URL = "http://api.jongtable.localhost/api/customer";

} else {

    API_URL = "https://api.jongtable.com/api/customer";

}

class CustomerService {

    find = (data) => {
        return new Promise((resolve, reject) => {
            return axios.post(API_URL + "/find", data)
                .then(response => {
                    return resolve(response);
                }).catch(error => {
                    return reject(error);
                })
        });
    }

    update = (data, customer_id) => {
        return new Promise((resolve, reject) => {
            return axios.put(API_URL + "/customer/" + customer_id, data)
                .then(response => {
                    return resolve(response);
                })
                .catch(error => {
                    return reject(error);
                });
        });
    }

    createOrder = (data, customer_id) => {
        return new Promise((resolve, reject) => {
            return axios.put(API_URL + "/customer/create-order/" + customer_id, data)
                .then(response => {
                    return resolve(response);
                })
                .catch(error => {
                    return reject(error);
                })
        })
    }

    orderLast = (customer_id) => {
        return new Promise((resolve, reject) => {
            return axios.get(API_URL + "/customer/customer-order-last/" + customer_id)
                .then(response => {
                    return resolve(response);
                })
                .catch(error => {
                    return reject(error);
                })
        })
    }

    completeOrder = (data, customer_id) => {
        return new Promise((resolve, reject) => {
            return axios.put(API_URL + "/customer/complete/" + customer_id, data)
                .then(response => {
                    return resolve(response);
                })
                .catch(error => {
                    return reject(error);
                })
        })
    }

    findCustomerOrder = (customer_id) => {
        return new Promise((resolve, reject) => {
            return axios.get(API_URL + "/customer/order/" + customer_id)
                .then(response => {
                    return resolve(response);
                })
                .catch(error => {
                    return reject(error);
                })
        })
    }

    findCustomerOrderDetail = (customer_order_id) => {
        return new Promise((resolve, reject) => {
            return axios.get(API_URL + "/customer/order/detail/" + customer_order_id)
                .then(response => {
                    return resolve(response);
                })
                .catch(error => {
                    return reject(error);
                })
        })
    }
}

export default new CustomerService()