import React from 'react';
import { Routes, Route, useParams, Outlet } from 'react-router-dom';
import Authenticate from './Layouts/Authenticate';
import Customer from './Layouts/Customer';
import Dashboard from './Layouts/Dashboard';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Register from './Pages/Register';
import "./App.css";
import Rent from './Pages/Rent';
import Order from './Pages/Order';
import User from './Pages/User';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrderDetail from './Pages/OrderDetail';

function App() {
    return (
        <React.Fragment>
            <Routes>
                {/* <Route element={<Customer />}>
                    <Route path="/" element={<Home />} />
                </Route> */}
                <Route element={<Customer />} >
                    <Route path="/:shop_prefix" element={<Home />} />
                    <Route path="/:shop_prefix/rent" element={<Rent />} />
                    <Route path="/:shop_prefix/user" element={<User />} />
                    <Route path="/:shop_prefix/order" element={<Order />} />
                    <Route path="/:shop_prefix/order/detail/:customer_order_id" element={<OrderDetail />} />
                </Route>
            </Routes>
            <ToastContainer />
        </React.Fragment>
    );
}

export default App;
