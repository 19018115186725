import React, { useEffect, useState } from 'react'
import { useOutlet, useParams } from 'react-router-dom';
import Header from '../Components/Parts/Header';
import Menu from '../Components/Parts/Menu';
import Footer from '../Components/Parts/Footer';
import { useDispatch } from 'react-redux';
import { getShop } from '../Actions/shop';
import loadingLottie from "../Assets/loading.gif";

export default function Customer() {

    const outlet = useOutlet();
    const dispath = useDispatch();
    const [loading, setLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        const shop_prefix = params.shop_prefix;
        dispath(getShop(shop_prefix))
            .then(response => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })
    }, [])

    const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <React.Fragment>
            <React.Fragment>
                {(loading) ?
                    <div className="loading-page">
                        <div className="loading-icon">
                            <img alt="true" src={loadingLottie} width={200} height={200} />
                        </div>
                    </div>
                    :
                    <React.Fragment>
                        <div id='layout-wrapper'>
                            <div className="main-content">
                                {outlet}
                            </div>
                        </div>
                        <button onClick={() => topFunction()} className="btn btn-danger btn-icon" id="back-to-top">
                            <i className="ri-arrow-up-line" />
                        </button>
                        <Footer />
                    </React.Fragment>
                }
            </React.Fragment>
        </React.Fragment>
    )
}
