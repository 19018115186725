import { CLEAR_BANK, CLEAR_SHOP, SET_BANK, SET_SHOP } from './type';
import ShopService from '../Services/ShopService';

export const getShop = (shop_prefix) => (dispatch) => {
    return ShopService.find(shop_prefix)
        .then(response => {
            console.log(response.data.shop);
            dispatch({
                type: SET_SHOP,
                payload: response.data.shop,
            });
            dispatch({
                type: SET_BANK,
                payload: response.data.banks,
            });
            return Promise.resolve(response);
        })
        .catch(error => {
            console.log(error);
            dispatch({
                type: CLEAR_SHOP,
                payload: {},
            });
            dispatch({
                type: CLEAR_BANK,
                payload: {},
            });
            return Promise.reject(error);
        })
}
