import { CLEAR_SHOP, SET_SHOP } from "../Actions/type";

export default function (state = {}, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_SHOP:
			return payload;
		case CLEAR_SHOP:
			return null;
		default:
			return state;
	}
}
