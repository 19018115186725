import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../Actions/auth';
import ShopService from '../Services/ShopService';
import CustomerService from '../Services/CustomerService';
import loadingLottie from "../Assets/loading.gif";
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import InputHook from '..//Components/Input/InputHook';
import ButtonHook from '..//Components/Input/ButtonHook';
import SelectHook from '..//Components/Input/SelectHook';
import UserUpdate from '../Components/UserUpdate';
import { notification } from '../Helpers';
import * as moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

const Home = () => {

    const shop = useSelector((state) => state.shop);
    const banks = useSelector((state) => state.banks);
    const [prefix, setPrefix] = useState(window.location.host.split(".")[0]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [customer, setCustomer] = useState();
    const [createModal, setCreateModal] = useState(false);
    const [user, setUser] = useState();
    const [message, setMessage] = useState();
    const [error, setError] = useState("");
    const [btnInfo, setBtnInfo] = useState(false);

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: useMemo(() => {
            return user;
        }, [user])
    });
    const liff = window.liff;

    useEffect(() => {
        findCustomer();
    }, []);
    const findCustomer = async () => {
        const values = {};
        // if (process.env.REACT_APP_ENV == "developer") {
        // values.shop_prefix = shop.prefix;
        // values.line_profile_url = "https://profile.line-scdn.net/0hbYDRd-NRPXZpKyg8E1JDCRl7PhxKWmRkEkt1GAx5ZUdSH3knQ097QF4oZRZQHXIlR0onQl94YUdlOEoQd33BQm4bY0FQHXknQUt1lA";
        // values.line_user_id = "U3149dd9197df4e98da6011bcbd340ae4";
        // values.line_display_name = "⚽️ ROCK SHIT";
        // dispatch(login(values))
        //     .then(response => {
        //         setUser(response.customer);
        //         if (response.customer.telephone == null || response.customer.name == null) {
        //             setCreateModal(true);
        //         }
        //         setLoading(false);
        //     }).catch(error => {
        //         console.log(error);
        //         setLoading(false);
        //     });
        // } else {
        const { userAgent } = navigator;
        await liff.init({ liffId: `1657265765-KGR1yPV9` }).catch(err => { throw err });
        // if (!userAgent.includes("Line")) {
        //     alert("กรุณาเข้าผ่าน browser LINE เท่านั้น");
        //     return;
        // }
        if (liff.isLoggedIn()) {
            let getProfile = await liff.getProfile();
            if (getProfile) {
                setMessage("LIFF init succeeded.");
                values.shop_prefix = shop.prefix;
                values.line_profile_url = getProfile.pictureUrl;
                values.line_user_id = getProfile.userId;
                values.line_display_name = getProfile.displayName;
                dispatch(login(values))
                    .then(response => {
                        setUser(response.customer);
                        if (response.customer.telephone == null || response.customer.name == null) {
                            setCreateModal(true);
                        }
                        setLoading(false);
                    }).catch(error => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        } else {
            liff.login();
        }
        // }
    }

    const updateUser = (values) => {
        setBtnInfo(true);
        CustomerService.update(values, user.id)
            .then(response => {
                setBtnInfo(false);
                notification("อัพเดทข้อมูลเรียรบร้อย", "success")
                setCreateModal(false);
            })
            .catch(error => {
                setBtnInfo(false);
                console.log(error);
            })

    }

    const goRent = () => {
        const now = moment().format("");
        const rent_start_time = moment(shop.rent_start_time, 'HH:mm:ss').format();
        const rent_end_time = moment(shop.rent_end_time, 'HH:mm:ss').format();
        if (user.type_id == 1) {
            if (shop.status == 0) {
                notification("ยังไม่ถึงเวลาที่ให้จองค่ะ", "danger");
                return;
            }
            if (now < rent_start_time || now > rent_end_time) {
                notification("ยังไม่ถึงเวลาที่ให้จองค่ะ", "danger");
                return;
            } else {
                navigate(`/${shop.prefix}/rent`);
            }
        } else if (user.type_id == 2) {
            navigate(`/${shop.prefix}/rent`);
        }
    }

    return (
        <React.Fragment>
            {(loading) ?
                <div className="loading-page">
                    <div className="loading-icon">
                        <img src={loadingLottie} alt width={200} height={200} />
                    </div>
                </div>
                :
                <React.Fragment>
                    <Modal show={createModal} onHide={() => { setCreateModal(false) }} backdrop="static">
                        <form onSubmit={handleSubmit(updateUser)} method="post">
                            <div className="modal-header bg-primary pb-2">
                                <h5 className="modal-title text-white" id="myModalLabel">📝 อัพเดทข้อมูลของคุณ</h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label htmlFor=""><i className='fad fa-user'></i> ชื่อผู้จอง</label>
                                        <InputHook type="text" field="name"
                                            errors={errors.name} register={register} errorLabel={{ requiredLabel: "กรุณาระบะชื่อร้าน" }}
                                            defaultValue={user.name} validates={{
                                                required: true,
                                            }} />
                                    </div>
                                    <div className="col-lg-6">
                                        <label htmlFor=""><i className='fad fa-phone'></i> เบอร์โทรติดต่อ</label>
                                        <InputHook type="tel" field="telephone"
                                            errors={errors.telephone} register={register} errorLabel={{ requiredLabel: "กรุณาระบุเบอร์โทรติดต่อ" }}
                                            defaultValue={user.telephone} validates={{
                                                required: true,
                                            }} />
                                    </div>
                                    <div className="col-lg-6">
                                        <label htmlFor=""><i className='fab fa-line'></i>  LINE_ID</label>
                                        <InputHook type="text" field="line_id"
                                            errors={errors.line_id} register={register} defaultValue={user.line_id} />
                                    </div>
                                </div>
                                {/* <div className="row mt-4">
                                    <hr />
                                    <div className="col-lg-12">
                                        <label htmlFor=""> <i className='fad fa-credit-card'></i> ข้อมูลธนาคารของคุณ</label>
                                        <SelectHook field="bank_id"
                                            options={banks}
                                            errors={errors.bank_id}
                                            register={register}
                                            defaultValue={user.bank_id}
                                            optionDefatulValue="เลือกธนาคาร"
                                            errorLabel={{ requiredLabel: "กรุณาระบุธนาคาร" }}
                                            validates={{
                                                required: true,
                                            }} />
                                    </div>
                                    <div className="col-lg-12">
                                        <InputHook type="tel" field="bank_account" placeholder="เลขที่บัญชี"
                                            errors={errors.bank_account} register={register} errorLabel={{ requiredLabel: "กรุณาระบุเลขบัญชี", minLengthLabel: "กรุณาระบุให้ครบ 10 หลักขึ้นไป" }}
                                            defaultValue={user.bank_account} validates={{
                                                required: true,
                                                minLength: 10
                                            }} />
                                    </div>
                                </div> */}
                            </div>
                            <div className="modal-footer">
                                <ButtonHook type="submit" className="btn btn-primary" isLoading={btnInfo} value="บันทึก" />
                            </div>
                        </form>
                    </Modal>
                    <div className="page-content">
                        <div className="container-fluid">
                            <img src={shop.logo_url} alt className="" height={100} />
                            <div className="card mt-2">
                                <div className="card-header bg-line">
                                    <h6 className="card-title text-white text-center mb-0">ร้าน {shop.name} ยินดีต้อนรับ</h6>
                                </div>
                                <div className="card-body">
                                    <p className="text-center">
                                        {shop.description}
                                    </p>
                                    <p className="text-center"> ⏰ เปิดให้จองโต้ะตั้งแต่ {shop.rent_start_time} ถึง {shop.rent_end_time} ⏰</p>
                                </div>
                            </div>
                            <button className="btn btn-primary btn-block w-100 mb-2" onClick={() => setCreateModal(true)}>

                                <span style={{ "fontSize": "20px" }}>📝</span>
                                <span> อัพเดทข้อมูลของคุณ</span>
                            </button>
                            <button className="btn btn-line bg-line btn-block w-100 mb-2" onClick={() => goRent()}>

                                <span style={{ "fontSize": "20px" }}>🪑</span>
                                <span> จองโต้ะ</span>
                            </button>
                            <Link to={`/${shop.prefix}/order`} className="btn btn-info text-white btn-block w-100">

                                <span style={{ "fontSize": "20px" }}>🗄</span>
                                <span> ประวัติการจอง</span>
                            </Link>
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment >
    );
}

export default Home;
