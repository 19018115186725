import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, REGISTER_FAIL, REGISTER_SUCCESS } from '../Actions/type';

const user = JSON.parse(window.localStorage.getItem("user"));

const initUser = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

export default function (state = initUser, action) {

    const { type, payload } = action;

    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: true
            }
        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false,

            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload
            }
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            }
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            }
        default:
            return state;
    }

}

