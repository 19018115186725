import React, { useEffect, useState } from 'react';
import ShopLayoutService from '../../Services/ShopLayoutService';
import loadingLottie from "../../Assets/loading.gif";
import PromotionService from '../../Services/PromotionService';
import Swal from 'sweetalert2';
import { currencyFormat } from '../../Helpers';
import ButtonHook from "../Input/ButtonHook";
import CustomerService from '../../Services/CustomerService';
import { notification } from '../../Helpers';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const Promotion = ({ setStep2, setStep3, shop, customer }) => {

    const [shopLayoutTables, setShopLayoutTables] = useState();
    const [loading, setLoading] = useState(true);
    const [promotions, setPromotions] = useState();
    const [totalPrice, setTotalPrice] = useState(0.00);
    const [btnLoading, setBtnLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getShopLayoutByCustomer();
        getPromotion();
        return () => {
        };
    }, []);

    const getShopLayoutByCustomer = async () => {
        await ShopLayoutService.getShopLayoutByCustomer(customer.id)
            .then(response => {
                let shopLayout = [];
                response.data.shop_layout_tables.map((v, k) => {
                    v.promotion_id = 0;
                    v.price = 0;
                    shopLayout.push(v);
                })
                setShopLayoutTables(shopLayout);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const getPromotion = async () => {
        await PromotionService.find(shop.id)
            .then(response => {
                // console.log(response.data.promotions);
                setPromotions(response.data.promotions);
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setLoading(false);
            })
    }

    const selectPromotion = (event, id) => {
        const promotion = promotions.find((v) => {
            return v.id == event.target.value;
        });
        let objIndex = shopLayoutTables.findIndex((obj => obj.id == id));
        if (promotion) {
            shopLayoutTables[objIndex].promotion_id = promotion.id;
            shopLayoutTables[objIndex].price = promotion.price;
            let sum = 0;
            shopLayoutTables.forEach(function (item) {
                sum += parseFloat(item.price);
            });
            setTotalPrice(sum);
        } else {
            shopLayoutTables[objIndex].promotion_id = "";
            shopLayoutTables[objIndex].price = 0;
        }
    }

    const createOrder = () => {
        const now = moment().format("");
        const rent_start_time = moment(shop.rent_start_time, 'HH:mm:ss').format();
        const rent_end_time = moment(shop.rent_end_time, 'HH:mm:ss').format();
        if (customer.type_id == 1) {
            if (now < rent_start_time || now > rent_end_time) {
                notification("ยังไม่ถึงเวลาที่ให้จองค่ะ", "danger");
                navigate(`/${shop.prefix}`);
                return;
            } else {
                navigate(`/${shop.prefix}/rent`);
            }
        }
        setBtnLoading(true);
        let fncCheckPromotion = shopLayoutTables.filter((v) => {
            return v.promotion_id == "";
        });
        console.log(fncCheckPromotion);
        if (fncCheckPromotion.length > 0) {
            Swal.fire(
                '',
                'กรุณาเลือกโปรโมชั่นให้ครบ',
                'warning'
            );
            setBtnLoading(false);
            return;
        }

        let data = {};

        data.shop_layout_tables = shopLayoutTables;

        data.total_price = totalPrice;

        CustomerService.createOrder(data, customer.id)
            .then(response => {
                if (response.data.status == 500) {
                    Swal.fire(
                        '',
                        response.data.message,
                        'warning'
                    );
                    setBtnLoading(false);
                    return;
                }
                setStep2("done");
                setStep3("active");
                setBtnLoading(false);
            })
            .catch(error => {
                console.log(error);
                setBtnLoading(false);
            });
    }

    return (
        <React.Fragment>
            {(loading) ?
                <div className="loading-page">
                    <div className="loading-icon">
                        <img src={loadingLottie} alt width={200} height={200} />
                    </div>
                </div>
                :

                <div className="card card-shadow mt-3">
                    <div class="card-header bg-line">
                        <h6 class="card-title mb-0 text-white">เลือกเครื่งดื่ม</h6>
                    </div>
                    <div className="card-body">
                        <p for="">ชื่อผู้จอง : {customer.name}</p>
                        <p for="">เบอร์โทรศัพทติดต่อ : {customer.telephone}</p>
                        <hr />
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th width="100">เลขโต้ะ</th>
                                    <th>เลือกโปรโมชั่น</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(shopLayoutTables) &&
                                    <React.Fragment>

                                        {(shopLayoutTables.map((v, k) => {
                                            return (
                                                <tr>
                                                    <td align='center' valign="middle">{v.name}</td>
                                                    <td>
                                                        <React.Fragment>
                                                            <select name="promotion_id" id="promotion_id" className="form-control"
                                                                onChange={(event) => selectPromotion(event, v.id)}>
                                                                <option value="">เลือก</option>
                                                                {(promotions &&
                                                                    <React.Fragment>
                                                                        {(promotions.map((v, k) => {
                                                                            return (
                                                                                <option value={v.id}>{v.name}</option>
                                                                            )
                                                                        }))}
                                                                    </React.Fragment>
                                                                )}
                                                            </select>
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                            )
                                        }))}
                                    </React.Fragment>
                                }
                            </tbody>
                        </table>
                        <div className="" style={{ float: "left !important" }}>
                            <p>
                                ราคารวม
                                <span className='pull-right'>
                                    {currencyFormat(totalPrice)} บาท
                                </span>
                            </p>
                        </div>
                        <ButtonHook value="ชำระเงิน" className="btn btn-primary btn-block w-100"
                            handleClick={() => createOrder()} isLoading={btnLoading}>
                        </ButtonHook>
                    </div>
                </div>
            }
        </React.Fragment >
    );
}

export default Promotion;
