import React, { useEffect, useState } from "react";
import ShopLayoutService from "../../Services/ShopLayoutService";
import loadingLottie from "../../Assets/loading.gif";
import { notification } from "../../Helpers";
import Swal from "sweetalert2";
import ButtonHook from "../Input/ButtonHook";
import { useNavigate } from "react-router-dom";
import socketIOClient from "socket.io-client";

const ShopLayout = ({ setStep1, setStep2, shop, customer }) => {
	const [loading, setLoading] = useState(true);
	const [shopLayoutTables, setShopLayoutTables] = useState();
	const [shopLayout, setShopLayout] = useState();
	const [lineTable, setLineTable] = useState(true);
	const [pointTable, setPointTable] = useState(false);
	const [markTables, setMarkTables] = useState([]);
	const [shopLayoutTableGroups, setShopLayoutTableGroups] = useState();
	const [btnLoading, setBtnLoading] = useState(false);
	const socket = socketIOClient("https://s0cket.jongtable.com");
	const navigate = useNavigate();

	useEffect(() => {
		if (!customer) {
			navigate(`/${shop.prefix}`);
		}
		checkShopLayoutByCustomer();
		getShopLayout();
		socket.on("get-shop-layout-table", function () {
			getShopLayout();
		});
		return () => socket.disconnect();
	}, []);

	const checkShopLayoutByCustomer = () => {
		ShopLayoutService.checkShopLayoutByCustomer(customer.id)
			.then((response) => {
				if (response.data.shop_layout_tables.length > 0) {
					setStep1("done");
					setStep2("active");
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getShopLayout = () => {
		ShopLayoutService.getShopLayout(shop.id)
			.then((response) => {
				setShopLayout(response.data.shop_layout);
				if (response.data.shop_layout.shop_layout_tables !== null) {
					setShopLayoutTables(response.data.shop_layout.shop_layout_tables);
					setShopLayoutTableGroups(response.data.shop_layout_table_groups);
				}
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const chosenPromotion = () => {
		setBtnLoading(true);
		ShopLayoutService.rentShopLayout(markTables, customer.id)
			.then((response) => {
				console.log(response);
				setBtnLoading(false);
				clearMarkTables();
				setStep1("done");
				setStep2("active");
				socket.emit("create-order");
			})
			.catch((error) => {
				console.log(error);
				setBtnLoading(false);
				clearMarkTables();
			});
	};

	const setPointerTd = (event, id, name, zone, r, c) => {
		event.preventDefault();
		if (markTables.length > 4) {
			Swal.fire("แจ้งเตือน", "จองได้สูงสุด 5 โต้ะ", "warning");
			return;
		}
		if (markTables.length == 0) {
			setMarkTables((markTables) => [
				...markTables,
				{
					id: id,
					name: name,
					zone: zone,
					row: r,
					column: c,
					promotion_id: "",
					price: 0,
				},
			]);
		} else {
			const checkUnique = fncCheckUnique(id);
			const checkZone = fncCheckZone(zone);
			const checkRowColumn = fncCheckRowColumn(r, c);
			const checkStuck = fncCheckStuck(r, c);
			if (!checkStuck) {
				Swal.fire("แจ้งเตือน", "ห้ามเลือกเว้นโต้ะ", "warning");
				return;
			}
			if (checkUnique.length > 0) {
				return;
			}
			if (!checkZone) {
				Swal.fire("", "กรุณาเลือกที่นั่งโซนเดียวกัน", "warning");
			} else if (checkRowColumn.length === 1 && markTables.length > 1) {
				Swal.fire("", "กรุณาเลือกที่นั่งติดกัน และ แถวเดียวกัน", "warning");
			} else {
				setMarkTables((markTables) => [
					...markTables,
					{
						id: id,
						name: name,
						zone: zone,
						row: r,
						column: c,
						promotion_id: "",
						price: 0,
					},
				]);
			}
		}
	};

	const fncCheckUnique = (id) => {
		return markTables.filter((v) => {
			return v.id === id;
		});
	};

	const fncCheckStuck = (r, c) => {
		const lastMark = Object.values(markTables).pop();
		const row = parseInt(lastMark.row) - r;
		const column = parseInt(lastMark.column) - c;
		let result = false;
		if (row == 0 && column == -1) {
			// console.log('ตั้ง', row, r, c);
			result = true;
		} else if (row == 0 && column == 1) {
			// console.log('นอน', column, r, c);
			result = true;
		} else if (row == -1 && column == 0) {
			// console.log('นอน', column, r, c);
			result = true;
		} else if (row == 1 && column == 0) {
			// console.log('นอน', column, r, c);
			result = true;
		}
		return result;
	};

	const fncCheckRowColumn = (r, c) => {
		const resultRowColumn = [];
		const checkRowColumn = markTables.filter((v, k) => {
			if (v.row === r) {
				resultRowColumn.push(v);
			} else if (v.column === c) {
				resultRowColumn.push(v);
			}
		});
		return resultRowColumn;
	};

	const fncCheckZone = (zone) => {
		const checkZone = markTables.find((v, k) => {
			return v.zone === zone;
		});
		return checkZone;
	};

	const getTR = (row, column) => {
		const objs = [];
		for (let r = 0; r < row; r++) {
			objs.push(<tr key={r}>{getTD(r, column)}</tr>);
		}
		return objs;
	};

	const getTD = (r, column) => {
		const objs = [];
		for (let c = 0; c < column; c++) {
			if (shopLayoutTables) {
				const table = shopLayoutTables.filter((v) => {
					return v.row == r && v.column == c;
				});
				if (table.length > 0) {
					let tableValue = shopLayoutTables.filter((v) => {
						return v.row == r && v.column == c;
					});
					objs.push(
						// <td>"sfds"</td>
						<SetTd table={tableValue[0]} />
					);
				} else {
					objs.push(
						<td key={c} className={`${lineTable ? `border ` : ``}`}>
							{}
						</td>
					);
				}
			} else {
				objs.push(
					<td key={c} className={`${lineTable ? `border ` : ``}`}>
						{}
					</td>
				);
			}
		}
		return objs;
	};

	const SetTd = (table) => {
		const tb = table.table;
		if (tb.type === 1) {
			var check = markTables.find((v, k) => {
				return v.id === tb.id;
			});
			var result = (
				<td>
					<button
						className={`btn color-${tb.zone}`}
						style={{
							width: "50px",
							height: "50px",
							borderRadius: "50%",
							color: "black",
							lineHeight: 1,
						}}
						onClick={(event) =>
							setPointerTd(event, tb.id, tb.name, tb.zone, tb.row, tb.column)
						}
						disabled={
							tb.status == 1 || tb.status == 2 || tb.customer_id != null
						}
					>
						<React.Fragment>
							{function () {
								if (tb.customer_id != null || tb.status == 1) {
									return (
										<div>
											<span style={{ display: "block", fontSize: "16px" }}>
												💳
											</span>
											<span style={{ fontSize: "9px" }}>{tb.name}</span>
										</div>
									);
								} else if (tb.status == 2) {
									return (
										<div>
											<span style={{ display: "block", fontSize: "16px" }}>
												✅
											</span>
											{/* <i className="fad fa-check"
                                            style={{
                                                display: "block",
                                                padding: "0px",
                                                fontSize: "18px",
                                                color: "green"
                                            }}></i> */}
											<span style={{ fontSize: "9px" }}>{tb.name}</span>
										</div>
									);
								} else {
									return (
										<div>
											{check ? (
												<span style={{ display: "block", fontSize: "16px" }}>
													👆🏻
												</span>
											) : (
												// <i className="fas fa-hand-pointer text-white"
												//     style={{
												//         display: "block",
												//         padding: "0px",
												//         fontSize: "16px"
												//     }}></i>
												<React.Fragment>
													<i
														className={tb.layout_table_type.icon}
														style={{
															display: "block",
															padding: "0px",
															fontSize: "16px",
														}}
													></i>
													<span style={{ fontSize: "9px" }}>{tb.name}</span>
												</React.Fragment>
											)}
										</div>
									);
								}
							}.call(this)}
						</React.Fragment>
					</button>
				</td>
			);
		} else {
			var result = (
				<td
					style={{
						width: "100px !important",
						height: "100px !important",
						boder: "1px solid red",
						borderRadius: tb.layout_table_type.radius,
						backgroundColor: tb.layout_table_type.bg_color,
						color: tb.layout_table_type.color,
					}}
					className="border pt-1"
				>
					<div
						style={{
							width: "50px",
							height: "50px",
							borderRadius: "50%",
							color: "black",
							color: tb.layout_table_type.color,
						}}
						disabled
					>
						<i className={tb.layout_table_type.icon}></i>
						<span style={{ fontSize: "9px", display: "block" }}>
							{tb.layout_table_type.name}
						</span>
					</div>
				</td>
			);
		}
		return result;
	};

	const clearMarkTables = () => {
		setMarkTables([]);
	};

	return (
		<React.Fragment>
			{loading ? (
				<div className="loading-page">
					<div className="loading-icon">
						<img src={loadingLottie} alt width={200} height={200} />
					</div>
				</div>
			) : (
				<React.Fragment>
					{markTables.length > 0 && (
						<div className="navbar-rent">
							<div class="card card-body">
								<div class="d-flex mb-2 align-items-center">
									<div class="flex-shrink-0">
										<img
											src={customer.line_profile_url}
											alt=""
											class="avatar-sm rounded-circle"
										/>
									</div>
									<div class="flex-grow-1 ms-2">
										<h5 class="card-title mb-1">{customer.name}</h5>
										<p class="text-muted mb-0">
											จำนวน {markTables.length} โต้ะ
										</p>
									</div>
								</div>
								<div class="btn-group" role="group" aria-label="Basic example">
									<button
										type="button"
										value="ถัดไป"
										className="btn btn-primary btn-block w-100 btn-sm mt-2"
										onClick={() => chosenPromotion()}
									>
										<i class="fa fa-check"></i>
									</button>
									<button
										className="btn btn-danger btn-block w-100 btn-sm mt-2"
										onClick={() => clearMarkTables()}
									>
										<i class="fa fa-times"></i>
									</button>
								</div>
							</div>
						</div>
					)}
					<div className="mt-3">
						<div className="">
							{shopLayout && (
								<React.Fragment>
									<div className="container-fluid">
										<div className="row">
											<div className="col-lg-12">
												<p className="text-center">
													<p className="">
														💳 : รอการชำระเงิน | ✅ : จองสำเร็จ
													</p>
													{shopLayoutTableGroups.map((v, k) => {
														return (
															<React.Fragment>
																<span class="badge badge-soft-secondary badge-border">
																	<i className={v.layout_table_type.icon}></i>{" "}
																	{v.layout_table_type.name}
																</span>
																&nbsp;
															</React.Fragment>
														);
													})}
												</p>
												<div className="table-responsive">
													<table className="layout">
														<thead>
															{getTR(shopLayout.row, shopLayout.column)}
														</thead>
													</table>
												</div>
											</div>
										</div>
									</div>
								</React.Fragment>
							)}
						</div>
					</div>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default ShopLayout;
