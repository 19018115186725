import React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from "react-router-dom";

const Footer = () => {

    const shop = useSelector((state) => state.shop)

    return (
        <React.Fragment>
            <div class="iconbar">
                <NavLink end to={`/${shop.prefix}`} class="icons">
                    <i class="fad fa-home d-block text-center pb-1"></i>
                    <span>หน้าแรก</span>
                </NavLink>
                <NavLink end to={`/${shop.prefix}/rent`} class="icons">
                    <i class="fad fa-chair d-block text-center pb-1"></i>
                    <span>จองโต้ะ</span>
                </NavLink>
                {/* <NavLink end to={`/${shop.prefix}/user`} class="icons">
                    <i class="fad fa-user d-block text-center pb-1"></i>
                    <span>ข้อมูล</span>
                </NavLink> */}
                <NavLink end to={`/${shop.prefix}/order`} class="icons">
                    <i class="fad fa-history d-block text-center pb-1"></i>
                    <span>ประวัติ</span>
                </NavLink>
            </div>
        </React.Fragment>
    );
}

export default Footer;
