import React from 'react'

export default function ButtonHook({ type, value, className, isLoading, icon, handleClick }) {
    return (
        <React.Fragment>
            <button type={type} className={className} disabled={isLoading} onClick={handleClick}>
                {(isLoading)
                    ?
                    <React.Fragment>
                        <span className="spinner-border spinner-border-sm"></span>
                        {value}
                    </React.Fragment>
                    :
                    <span>
                        <i className={icon}></i>
                        {value}
                    </span>
                }
            </button>
        </React.Fragment>
    )
}
