import axios from "axios";

let API_URL = "";

if (process.env.REACT_APP_ENV == "developer") {

    API_URL = "http://api.jongtable.localhost/api/customer";

} else {

    API_URL = "https://api.jongtable.com/api/customer";

}

class AuthService {

    register = (name, username, password) => {
        return new Promise((resolve, reject) => {
            return axios.post(API_URL + "/auth/register", {
                name: name,
                username: username,
                password: password
            }).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }

    login = (username, password) => {
        return new Promise((resolve, reject) => {
            return axios.post(API_URL + "/auth/login", {
                username: username,
                password: password
            }).then(response => {
                if (response.data.user) {
                    window.localStorage.setItem("user", JSON.stringify(response.data.user));
                }
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        })
    }

    logout = () => {
        window.localStorage.removeItem("user")
    }
}

export default new AuthService()