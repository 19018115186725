export default function InputHook({ errors, register, type, errorLabel, field, validates, defaultValue, placeholder }) {

    return (
        <div>
            <input type={type}
                className={errors ? "form-control is-invalid form-control" : "form-control invalid"}
                name={field} {...register(field, validates)}
                defaultValue={defaultValue}
                placeholder={placeholder}
            />
            <span className='invalid-feedback'>
                {errors && errors.type === "required" && (
                    <span role="alert">{errorLabel.requiredLabel}</span>
                )}
                {errors && errors.type === "minLength" && (
                    <span role="alert">{errorLabel.minLengthLabel}</span>
                )}
                {errors && errors.type === "validate" && (
                    <span role="alert">{errorLabel.validateLabel}</span>
                )}
                {errors && errors.type === "maxLength" && (
                    <span role="alert">{errorLabel.maxLengthLabel}</span>
                )}
            </span>
        </div>
    )
}
