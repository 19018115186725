export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER FAIL";
export const LOGIN_SUCCESS = "LOGIN SUCCESS";
export const LOGIN_FAIL = "LOGIN FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_SHOP = "SET_SHOP";
export const CLEAR_SHOP = "CLEAR_SHOP";

export const SET_BANK = "SET_BANK";
export const CLEAR_BANK = "CLEAR_BANK";