import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clipboard } from '../../Helpers';
import CustomerService from '../../Services/CustomerService';
import ButtonHook from '../Input/ButtonHook';
import InputHook from '../Input/InputHook';
import SelectHook from '../Input/SelectHook';

const Payment = ({ setStep3, setStep4, customer, customerOrder }) => {
    const shop = useSelector(state => state.shop);
    const banks = useSelector((state) => state.banks);
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: useMemo(() => {
            return customer;
        }, [customer])
    });
    const [btnLoading, setBtnLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // console.log(shop);
    }, []);

    const completeOrder = (values) => {
        setBtnLoading(true);
        let data = {};
        data.customer_id = values.customer_id;
        data.bank_id = values.bank_id;
        data.bank_account = values.bank_account;
        CustomerService.completeOrder(data, customerOrder.id)
            .then(response => {
                console.log(response);
                setBtnLoading(false);
                navigate(`/${shop.prefix}/order/detail/` + customerOrder.id);
            })
            .catch(error => {
                console.log(error);
                setBtnLoading(false);
            })
    }
    return (
        <React.Fragment >
            <div className="card card-shadow mt-3">
                <div class="card-header bg-line">
                    <h6 class="card-title mb-0 text-white">ชำระเงิน</h6>
                </div>
                <div className="card-body">
                    {/* <p>
                        💳 บัญชีของทางร้าน
                    </p>
                    <hr />
                    {(shop) &&
                        <React.Fragment>
                            {shop.shop_bank_accounts.map((v, k) => {
                                return (
                                    <div className="alert alert-primary" role="alert" style={{ backgroundColor: v.bank.bg_color, color: v.bank.font_color }} key={k}>
                                        <img src={`https://file.jongtable.com/uploads/${v.bank.logo}`} className="avatar-sm rounded-circle" />{v.bank.name}
                                        <span className='d-block '>
                                            {v.name}
                                        </span>
                                        <span className='d-block '>
                                            {v.account}
                                        </span>
                                        <button className='btn btn-sm btn-primary pull-right' onClick={(e) => clipboard(e.target.value, customerOrder.total_price)} value={v.account}>
                                            <i className='fad fa-copy' style={{ marginRight: "10px" }}></i>
                                            คัดลอกเลขที่บัญชี
                                        </button>
                                    </div>
                                )
                            })}
                        </React.Fragment>
                    } */}
                    <p className="">⚠️ หมายเหตุ</p>
                    <hr />
                    <p>ลูกค้าต้องใช้ <b>บัญชีที่ระบุโอนมา</b> เท่านั้น หากมีการโอนผิดทางร้านจะขอยกเลิกโต้ะที่ลูกค้าจองไว้ และทำการโอนเงินคืนให้นะคะ</p>
                    <p htmlFor="">  🏦 ข้อมูลธนาคารของคุณที่ใช้โอน</p>
                    <hr />
                    <form onSubmit={handleSubmit(completeOrder)} method="post">
                        <div className="col-lg-12">
                            <label for="">ธนาคาร</label>
                            <SelectHook field="bank_id"
                                options={banks}
                                errors={errors.bank_id}
                                register={register}
                                defaultValue={customer.bank_id}
                                optionDefatulValue="เลือกธนาคาร"
                                errorLabel={{ requiredLabel: "กรุณาระบุธนาคาร" }}
                                validates={{
                                    required: true,
                                }} />
                        </div>
                        <div className="col-lg-12">
                            <label for="">เลขที่บัญชี </label>
                            <InputHook type="tel" field="bank_account" placeholder="เลขที่บัญชี"
                                errors={errors.bank_account} register={register} errorLabel={{ requiredLabel: "กรุณาระบุเลขบัญชี", minLengthLabel: "กรุณาระบุให้ครบ 10 หลักขึ้นไป" }}
                                defaultValue={customer.bank_account} validates={{
                                    required: true,
                                    minLength: 10
                                }} />
                            <small className='text-danger'>*ไม่ต้องใส่ขีด</small>
                        </div>
                        <hr />
                        <ButtonHook to={`/${shop.prefix}/order`} value="ยืนยันข้อมูลการชำระเงิน" className="btn btn-primary btn-block w-100"
                            isLoading={btnLoading} />
                    </form>
                </div>
            </div>
        </React.Fragment >
    )
}

export default Payment;
