export default function SelectHook({ errors, register, type, errorLabel, field, validates, options, onChangeHandler, defaultValue, optionDefatulValue = "เลือก" }) {
    return (
        <div>
            <select type={type}
                className={errors ? "form-control is-invalid form-control" : "form-control invalid"}
                name={field} {...register(field, validates)} onChange={onChangeHandler} defaultValue={defaultValue}>
                <option value="">{optionDefatulValue}</option>
                {(options.map((v, k) => {
                    return <option value={v.id} key={k}
                    >{v.name}</option>
                }))}
            </select>
            <span className='invalid-feedback'>
                {errors && errors.type === "required" && (
                    <span role="alert">{errorLabel.requiredLabel}</span>
                )}
                {errors && errors.type === "minLength" && (
                    <span role="alert">{errorLabel.minLengthLabel}</span>
                )}
                {errors && errors.type === "validate" && (
                    <span role="alert">{errorLabel.validateLabel}</span>
                )}
            </span>
        </div>
    )
}
