import { CLEAR_BANK, SET_BANK } from '../Actions/type';

export default function (state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_BANK:
            return payload;
        case CLEAR_BANK:
            return null
        default:
            return state;
    }
}