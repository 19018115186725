import axios from "axios";

let API_URL = "";

if (process.env.REACT_APP_ENV == "developer") {

    API_URL = "http://api.jongtable.localhost/api/customer";

} else {

    API_URL = "https://api.jongtable.com/api/customer";

}

class ShopLayoutService {

    getShopLayout = (shop_id) => {
        return new Promise((resolve, reject) => {
            return axios.get(API_URL + "/shop-layout/" + shop_id).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }

    rentShopLayout = (markTables, customer_id) => {
        return new Promise((resolve, reject) => {
            return axios.post(API_URL + "/shop-layout/reserve", {
                mark_tables: markTables,
                customer_id: customer_id
            }).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }

    getShopLayoutByCustomer = (customer_id) => {
        return new Promise((resolve, reject) => {
            return axios.get(API_URL + "/shop-layout/check/" + customer_id).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }

    checkShopLayoutByCustomer = (customer_id) => {
        return new Promise((resolve, reject) => {
            return axios.get(API_URL + "/shop-layout/check/" + customer_id).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }
}

export default new ShopLayoutService()