import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../Actions/auth';
import ShopService from '../Services/ShopService';
import CustomerService from '../Services/CustomerService';
import loadingLottie from "../Assets/loading.gif";

const User = () => {

    const shop = useSelector((state) => state.shop);
    const [prefix, setPrefix] = useState(window.location.host.split(".")[0]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [customer, setCustomer] = useState();

    useEffect(() => {
        findCustomer();
    }, []);
    const findCustomer = async () => {
        setLoading(false);
    }

    return (
        <React.Fragment>
            {(loading) ?
                <div className="loading-page">
                    <div className="loading-icon">
                        <img src={loadingLottie} alt width={200} height={200} />
                    </div>
                </div>
                :
                <React.Fragment>
                    <div className="page-content pt-4">
                        <div className="container-fluid">
                            <img src={shop.logo_url} alt="" className="img-center" height={100} />
                            <div className="card mt-2">
                                <div className="card-header bg-line">
                                    <h6 class="card-title text-white text-center mb-0">ข้อมูลของคุณ</h6>
                                </div>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    );
}

export default User;
