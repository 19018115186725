import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../Actions/auth';
import loadingLottie from "../Assets/loading.gif";
import { clipboard, currencyFormat } from '../Helpers';
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import CustomerService from '../Services/CustomerService';

const OrderDetail = () => {
    const [loading, setLoading] = useState(true);
    const shop = useSelector((state) => state.shop);
    const { user } = useSelector((state) => state.auth);
    const params = useParams();
    const [customerOrder, setCustomerOrder] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        CustomerService.findCustomerOrderDetail(params.customer_order_id)
            .then(response => {
                setCustomerOrder(response.data.customer_order);
                if (response.data.customer_order.customer_id != user.id) {
                    navigate(`/${shop.prefix}`);
                }
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            })
    }, [])

    return (
        <div>
            <React.Fragment>
                {(loading) ?
                    <div className="loading-page">
                        <div className="loading-icon">
                            <img src={loadingLottie} alt width={200} height={200} />
                        </div>
                    </div>
                    :
                    <React.Fragment>

                        <div className="page-content mb-5">
                            <div className="container-fluid">
                                <img src={shop.logo_url} alt className="" height={100} />
                                {(customerOrder) &&
                                    <React.Fragment>
                                        <div class="card mt-2">
                                            <div class="card-header bg-line">
                                                <h6 class="card-title text-white text-center mb-0">รายละเอียดการจอง</h6>
                                            </div>

                                            <div className="card-body">
                                                {(customerOrder.status != 0) ?
                                                    <React.Fragment>
                                                        {(customerOrder.status == 2) ?
                                                            <React.Fragment>
                                                                <p className='text-center text-success'> <i className='fad fa-check'></i> การจองสำเร็จ </p>
                                                                <p className='text-center text-success'>กรุณามาก่อนเวลา 20.00 นะคะ</p>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <p className='text-center text-danger'> <i className='fad fa-exclamation-circle'></i> กรุณาชำระเงินก่อนเวลา</p>
                                                                <h3 className='text-center'>{moment(customerOrder.expire_date, 'Y-MM-DD HH:mm:ss').format('HH:mm:ss')} น</h3>
                                                            </React.Fragment>
                                                        }
                                                        <table className='table'>
                                                            <thead className="thead-dark">
                                                                <tr>
                                                                    <th>เลขโต้ะ</th>
                                                                    <th>เลือกโปรโมชั่น</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(customerOrder.customer_order_tables.map((v, k) => {
                                                                    return (
                                                                        <tr key={k}>
                                                                            <td align='center' valign="middle">{v.shop_layout_table.name}</td>
                                                                            <td>
                                                                                {v.promotion.name}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }))}
                                                            </tbody>
                                                        </table>
                                                        <div className='pull-right'>
                                                            รวม {currencyFormat(parseFloat(customerOrder.total_price))} บาท
                                                        </div>
                                                        <p className=''>วันที่จอง {moment(customerOrder.created_at, 'Y-MM-DD HH:mm:ss').format('DD/MM/Y')}</p>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <h3 className='text-center'>
                                                            <i className="fad fa-engine-warning text-danger fa-2x"></i>
                                                        </h3>
                                                        {/* <p className='text-center text-danger'> <i className='fad fa-exclamation-circle'></i> กรุณาชำระเงินก่อนเวลา</p> */}
                                                        <h3 className='text-center text-danger'>หมดเวลาการชำระเงินแล้วค่ะ</h3>
                                                    </React.Fragment>
                                                }
                                                {(customerOrder.status != 0 && customerOrder.status != 2) &&
                                                    <React.Fragment>
                                                        <p className="">⚠️ หมายเหตุ</p>
                                                        <hr />
                                                        <div className="mb-4 text-center">
                                                            <img src={`https://file.jongtable.com/uploads/${customerOrder.bank.logo}`} width="30" className="rounded-circle" />&nbsp;&nbsp;XXXXXX{customerOrder.bank_account.substring(4)}
                                                        </div>
                                                        <p>ลูกค้าต้องใช้ <b>บัญชีที่ระบุโอนมา</b> เท่านั้น หากมีการโอนผิดทางร้านจะขอยกเลิกโต้ะที่ลูกค้าจองไว้ และทำการโอนเงินคืนให้นะคะ</p>
                                                        {/* <hr /> */}
                                                        <p htmlFor="">  🏦 โอนเงินเข้าบัญชีธนาคาร</p>
                                                        <hr />
                                                        {shop.shop_bank_accounts.map((v, k) => {
                                                            return (
                                                                <div className="alert alert-primary" role="alert" style={{ backgroundColor: v.bank.bg_color, color: v.bank.font_color }} key={k}>
                                                                    <img src={`https://file.jongtable.com/uploads/${v.bank.logo}`} width="50" className="rounded-circle" />{v.bank.name}
                                                                    <span className='d-block '>
                                                                        {v.name}
                                                                    </span>
                                                                    <span className='d-block '>
                                                                        {v.account}
                                                                    </span>
                                                                    <button className='btn btn-sm btn-primary pull-right' onClick={(e) => clipboard(e.target.value, customerOrder.total_price)} value={v.account}>
                                                                        <i className='fad fa-copy' style={{ marginRight: "10px" }}></i>
                                                                        คัดลอกเลขที่บัญชี
                                                                    </button>
                                                                </div>
                                                            )
                                                        })}
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        </div >
    );
}

export default OrderDetail;
