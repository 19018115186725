import axios from 'axios';

export const AxiosInterceptor = (store, history) => {

    // axios.defaults.baseURL = 'https://api.example.com';
    // const user = JSON.parse(window.localStorage.getItem("user"));
    const user = JSON.parse(window.localStorage.getItem("user"));
    // const user = {};
    if (user) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access_token;
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json';

} 