import axios from "axios";

let API_URL = "";

if (process.env.REACT_APP_ENV == "developer") {

    API_URL = "http://api.jongtable.localhost/api/customer";

} else {

    API_URL = "https://api.jongtable.com/api/customer";

}

class ShopService {

    find = (shop_prefix) => {
        return new Promise((resolve, reject) => {
            return axios.post(API_URL + "/find/shop", {
                shop_prefix: shop_prefix
            }).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }
}

export default new ShopService()