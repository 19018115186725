import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../Actions/auth';
import ShopService from '../Services/ShopService';
import CustomerService from '../Services/CustomerService';
import loadingLottie from "../Assets/loading.gif";
import { currencyFormat } from '../Helpers';
import { Link } from "react-router-dom";
import moment from "moment";

const Order = () => {

    const shop = useSelector((state) => state.shop);
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(true);
    const [prefix, setPrefix] = useState(window.location.host.split(".")[0]);
    const dispatch = useDispatch();
    const [customerOrders, setCustomerOrders] = useState();

    useEffect(() => {
        findCustomerOrders();
    }, []);
    const findCustomerOrders = async () => {
        CustomerService.findCustomerOrder(user.id)
            .then(response => {
                setCustomerOrders(response.data.customer_orders);
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setLoading(false);
            })
    }

    return (
        <React.Fragment>
            {(loading) ?
                <div className="loading-page">
                    <div className="loading-icon">
                        <img src={loadingLottie} alt width={200} height={200} />
                    </div>
                </div>
                :
                <React.Fragment>


                    <div className="page-content">
                        <div className="container-fluid">
                            <img src={shop.logo_url} alt="" height={100} />
                            <div className="card mt-2">
                                <div className="card-header bg-line">
                                    <h6 class="card-title text-white text-center mb-0">ประวัติการจองโต้ะ</h6>
                                </div>
                                <div className="card-body">
                                    {(customerOrders) &&
                                        <React.Fragment>
                                            <div className="list-group">
                                                {(customerOrders.map((v, k) => {
                                                    return (
                                                        <React.Fragment>
                                                            <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                                                                <div class="d-flex w-100 justify-content-between">
                                                                    <h5 class="mb-1">{v.code}</h5>
                                                                    <small>วันที่จอง: {moment(v.createdAt).format('DD/MM/Y H:mm')}</small>
                                                                </div>
                                                                <p class="mb-1">
                                                                    รวม {currencyFormat(parseFloat(v.total_price))} บาท</p>
                                                                <small className=''>
                                                                    <div className='pull-right'>
                                                                        <Link to={`/${shop.prefix}/order/detail/${v.id}`} className='btn btn-info btn-sm'>
                                                                            ดูรายละเอียด <i className='fa fa-angle-right'></i>
                                                                        </Link>
                                                                    </div>
                                                                    {(() => {
                                                                        if (v.status == 0) {
                                                                            return (
                                                                                <span className='btn btn-sm btn-secondary'> <i className='fa fa-times'></i> หมดเวลาการชำระเงิน</span>
                                                                            )
                                                                        } else if (v.status == 1) {
                                                                            return (
                                                                                <span className='btn btn-sm btn-warning'> <i className='fa fa-history'></i> รอการโอนเงิน</span>
                                                                            )
                                                                        } else if (v.status == 2) {
                                                                            return (
                                                                                <span className='btn btn-sm btn-success'> <i className='fa fa-check'></i>  จองสำเร็จ</span>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </small>
                                                            </a>
                                                        </React.Fragment>
                                                    )
                                                }))}
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    );
}

export default Order;
