import axios from "axios";

let API_URL = "";

if (process.env.REACT_APP_ENV == "developer") {

    API_URL = "http://api.jongtable.localhost/api/customer";

} else {

    API_URL = "https://api.jongtable.com/api/customer";

}

class PromotionService {

    find = (shop_id) => {
        return new Promise((resolve, reject) => {
            return axios.get(API_URL + "/promotion/" + shop_id).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }
}

export default new PromotionService()