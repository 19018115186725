import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../Actions/auth';
import ShopService from '../Services/ShopService';
import CustomerService from '../Services/CustomerService';
import loadingLottie from "../Assets/loading.gif";
import { notification } from '../Helpers';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ShopLayout from '../Components/Rent/ShopLayout';
import Promotion from '../Components/Rent/Promotion';
import Payment from '../Components/Rent/Payment';

const Rent = () => {

    const shop = useSelector((state) => state.shop);
    const { user } = useSelector((state) => state.auth);
    const [prefix, setPrefix] = useState(window.location.host.split(".")[0]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [step1, setStep1] = useState("active");
    const [step2, setStep2] = useState("");
    const [step3, setStep3] = useState("");
    const [step4, setStep4] = useState("");
    const [customerOrder, setCustomerOrder] = useState();

    useEffect(() => {
        customerOrderLast();
        checkRentTime()
    }, [step2]);

    const customerOrderLast = () => {
        CustomerService.orderLast(user.id)
            .then(response => {
                setCustomerOrder(response.data.customer_order_last);
                checkRentTime();
                if (response.data.customer_order_last != null) {
                    if (response.data.customer_order_last.status == 1 && response.data.customer_order_last.bank_id != null) {
                        notification("คุณมีรายการจองค้างอยู่", "danger");
                        navigate(`/${shop.prefix}/order`);
                        return
                    }
                    if (response.data.customer_order_last.status == 2) {
                        notification("คุณสามารถจองได้ 1 ครั้งต่อวัน", "danger");
                        navigate(`/${shop.prefix}/order`);
                        return
                    }
                    setStep1("done");
                    setStep2("done");
                    setStep3("active");
                }
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const checkRentTime = () => {
        const now = moment().format("");
        const rent_start_time = moment(shop.rent_start_time, 'HH:mm:ss').format();
        const rent_end_time = moment(shop.rent_end_time, 'HH:mm:ss').format();
        if (user.type_id == 1) {
            if (shop.status == 0) {
                notification("ยังไม่ถึงเวลาที่ให้จองค่ะ", "danger");
                navigate(`/${shop.prefix}`);
                return;
            }
            if (now < rent_start_time || now > rent_end_time) {
                notification("ยังไม่ถึงเวลาที่ให้จองค่ะ", "danger");
                navigate(`/${shop.prefix}`);
                return;
            } else {
                navigate(`/${shop.prefix}/rent`);
            }
        } else if (user.type_id == 2) {
            navigate(`/${shop.prefix}/rent`);
        }
    }

    const RentComponent = () => {
        if (step1 == "active") {
            console.log("step 1");
            return <ShopLayout />;
        }
    }

    return (
        <React.Fragment>
            {(loading) ?
                <div className="loading-page">
                    <div className="loading-icon">
                        <img src={loadingLottie} alt width={200} height={200} />
                    </div>
                </div>
                :
                <React.Fragment>
                    <div className="page-content">
                        <div className="container-fluid">

                            <img src={shop.logo_url} alt="" className="" height={100} />
                            <div class="step-arrow-nav mb-2 mt-2">
                                <ul class="nav nav-pills custom-nav nav-justified" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class={`nav-link link-1 ${step1}`}>
                                            <span className='d-block'>
                                                <i className='fad fa-chair'></i>
                                            </span>
                                            <span>จองโต้ะ</span>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class={`nav-link link-2 ${step2}`} >
                                            <span className='d-block'>
                                                <i className='fad fa-glass-martini'></i>
                                            </span>
                                            <span>เครื่องดื่ม</span></button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class={`nav-link link-3 ${step3}`} >
                                            <span className='d-block'>
                                                <i className='fad fa-credit-card-front'></i>
                                            </span>
                                            <span>ชำระเงิน</span></button>
                                    </li>
                                    {/* <li class="nav-item" role="presentation">
                                        <button class={`nav-link link-4 ${step4}`} >
                                            <span className='d-block'>
                                                <i className='fad fa-check'></i>
                                            </span>
                                            <span>ออเดอร์</span></button>
                                    </li> */}
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane active" id="home-1" role="tabpanel">
                                        {(() => {
                                            if (step1 == "active") {
                                                return <ShopLayout setStep1={setStep1} setStep2={setStep2} shop={shop} customer={user} />
                                            } else if (step2 == "active") {
                                                return <Promotion setStep2={setStep2} setStep3={setStep3} shop={shop} customer={user} />
                                            } else if (step3 == "active") {
                                                return <Payment setStep3={setStep3} setStep4={setStep4} customer={user} customerOrder={customerOrder} />
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    );
}

export default Rent;
